import type { ServerResponse } from 'http'

type Timing = {
  name: string
  start: number | null
  end: number | null
  time: number | null
}

export const timingTelemetry = new (class {
  public timings: Array<Timing> = []

  public time: number | null = null

  start(name: string): void {
    const hasTiming = this.timings.find((timing: Timing) => timing.name === name)

    if (hasTiming) {
      this.timings = this.timings.map((timing) => {
        return {
          ...timing,
          start: performance.now(),
        }
      })
    } else {
      this.timings.push({
        name,
        start: performance.now(),
        end: null,
        time: null,
      })
    }

    if (typeof this.time !== 'number') {
      this.time = performance.now()
    }
  }

  end(name: string): void {
    this.timings = this.timings.map((timing) => {
      if (timing.name === name && typeof timing.start === 'number') {
        const end = performance.now()

        return {
          ...timing,
          end,
          time: end - timing.start,
        }
      }

      return timing
    })

    if (typeof this.time === 'number') {
      this.time = performance.now() - this.time
    }
  }

  log(label?: string): void {
    let log = ``

    log += `Timing telemetry ${label}: `

    this.timings.forEach((timing, key) => {
      if (typeof timing.time === 'number') {
        log += `[${key + 1}]: ${timing.name} - ${timing.time.toFixed(2)}ms; `
      } else {
        log += `[${key + 1}]: ${timing.name} - not called end(); `
      }
    })

    console.log(log)
  }

  get(): Timing[] {
    return this.timings
  }

  setServerTiming(res?: ServerResponse): void {
    if (res) {
      const result = [] as string[]
      let total = 0

      this.timings.forEach((timing) => {
        if (typeof timing.time === 'number') {
          const name = timing.name.replace(/\s+/g, '.')

          total += Number(timing.time.toFixed(0))

          result.push(`${name};dur=${timing.time.toFixed(0)}`)
        }
      })

      result.push(`total;dur=${total}`)

      res.setHeader('server-timing', result)
    }
  }

  getTime(): string | null {
    if (typeof this.time === 'number') {
      return `${this.time.toFixed(2)}ms`
    }

    return null
  }
})()
